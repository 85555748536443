import { useState } from "react";

import { Box, Button, Divider, InputAdornment, Stack, TextField, Typography } from "@mui/material";

import { AddIcon, SearchIcon, TagIcon } from "components/icons";
import { RatingIcon } from "features/rating";
import { TagChip } from "features/tag";
import { useSearchTagsQuery } from "../../api";


export interface ReviewFormTagsProps {
    tags: string[]
    onSelect: (tag: string) => void
}

export const ReviewFormTags = ({ tags, onSelect }: ReviewFormTagsProps) => {
    // TODO(souperk): Introduce a tag limit (max 10).
    const [query, setQuery] = useState("")
    const { data: { result: positiveTags } = { result: [] } } = useSearchTagsQuery({ query, type: "review" })
    const { data: { result: negativeTags } = { result: [] } } = useSearchTagsQuery({ query, type: "review" })


    const handleCreateTag = () => {
        onSelect(query)
        setQuery("")
    }

    return (
        <Stack gap={2} sx={{ height: "100%" }}>
            <TextField
                placeholder="Search ..."
                value={query}
                onChange={event => setQuery(event.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon/>
                        </InputAdornment>
                    ),
                }}
                sx={{ width: "100%" }}
            />

            {query.length > 3 && (
                <Button variant="outlined" color="primary" startIcon={<AddIcon/>} onClick={handleCreateTag}
                        sx={{ textTransform: "none", justifyContent: "flex-start" }}>
                    #{query}
                </Button>
            )}

            <Box>
                <Stack direction="row" gap={2} alignItems="center">
                    <RatingIcon rating="positive"/>
                    <Typography variant="h6" component="span">
                        Positive
                    </Typography>
                </Stack>
                <Divider/>
            </Box>
            <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
                {positiveTags.map(tag =>
                    <TagChip
                        key={tag.slug}
                        tag={tag.slug}
                        selected={tags.includes(tag.slug)}
                        onClick={() => onSelect(tag.slug)}
                    />
                )}
            </Stack>

            <Box>
                <Stack direction="row" gap={2} alignItems="center">
                    <RatingIcon rating="negative"/>
                    <Typography variant="h6" component="span">
                        Negative
                    </Typography>
                </Stack>
                <Divider/>
            </Box>
            <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
                {negativeTags.map(tag =>
                    <TagChip
                        key={tag.slug}
                        tag={tag.slug}
                        selected={tags.includes(tag.slug)}
                        onClick={() => onSelect(tag.slug)}
                    />
                )}
            </Stack>

            <Box>
                <Stack direction="row" gap={2} alignItems="center">
                    <TagIcon/>
                    <Typography variant="h6" component="span">
                        Selected
                    </Typography>
                </Stack>
                <Divider/>
            </Box>
            <Stack gap={1} direction="row" flexWrap="wrap" alignItems="center">
                {tags.map(tag =>
                    <TagChip
                        key={tag}
                        tag={tag}
                        selected
                        onClick={() => onSelect(tag)}
                    />
                )}
            </Stack>
        </Stack>
    )
}

export const TagsList = [
    "reckless-driving",
    "clean-car",
    "sexist",
    "racist",
    "bad-behavior",
]
