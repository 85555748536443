import { useCallback, useContext, useEffect, useMemo, useRef } from "react";

import Leaflet from "leaflet";
import "leaflet-routing-machine"
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { LeafletContext } from "./LeafletContext";


export interface LeafletRoutingControlProps {
    coords: [number, number][]
    onRouteFound?: (event: Leaflet.Routing.RoutingResultEvent) => void
}

export const LeafletRoutingControl = ({ coords, onRouteFound }: LeafletRoutingControlProps) => {
    console.log("leaflet: routing control component")
    const context = useContext(LeafletContext)
    const control = useRef<Leaflet.Routing.Control>()

    // TODO(souperk): Fix icon quality
    // TODO(souperk): Display name text on top of markers
    const markerIcon = useMemo(() => new Leaflet.Icon({
        iconUrl: markerIconPng,
        iconRetinaUrl: markerIconPng,
        // shadowUrl: markerIconPng,
        // shadowRetinaUrl: markerIconPng,
        iconAnchor: [6, 18],
        iconSize: [12, 18]
    }), [])

    const handleRouteFound = useCallback((event: Leaflet.Routing.RoutingResultEvent) => {
        onRouteFound?.(event)
    }, [onRouteFound])

    useEffect(() => {
        if (!context.initialized) {
            // wait for the context to be ready
            return
        }

        if (control.current) {
            // The control has already been initialized but
            console.warn("leaflet: routing control has already been initialized")
            return
        }

        console.log("leaflet: routing control initialization")
        control.current = new Leaflet.Routing.Control({
            plan: new Leaflet.Routing.Plan([], {
                draggableWaypoints: false,
                createMarker: (i, wp, nWps) => {
                    return new Leaflet.Marker(wp.latLng, { icon: markerIcon })
                }
            }),
            routeWhileDragging: false,
            fitSelectedRoutes: false,
            autoRoute: true,
        })
        control.current.addTo(context.map)
        control.current.on("routesfound", (e) => {
            handleRouteFound(e)
        })

        // Hide route instructions panel
        const el = document.getElementsByClassName('leaflet-routing-container');
        el[0]?.remove()


        // return destructor
        return () => {
            console.log("leaflet: routing control cleanup")
            control.current?.remove?.()
        }
    }, [context.initialized, context.map, handleRouteFound, markerIcon])


    useEffect(() => {
        if (!context.initialized) {
            // wait for the context
            return
        }

        if (!control.current) {
            // wait for the control
            return
        }

        console.log("leaflet: routing control update")
        const waypoints = coords.map(waypoint => Leaflet.latLng(waypoint))
        control.current.setWaypoints(waypoints)
        if (coords.length === 1) {
            context.map.flyTo(coords[0]);
        } else if (coords.length >= 2) {
            context.map.flyToBounds(coords);
        }

        return () => {
            control.current?.hide()
        }
    }, [context.initialized, context.map, coords])

    return null
}