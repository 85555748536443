import React, { forwardRef, useMemo } from "react";
import dayjs from "dayjs";
import { Box, BoxProps, Grid, styled } from "@mui/material";
import { DateTimeField } from "@mui/x-date-pickers";

import { LocationField } from "components/LocationField";

import { useAppDispatch, useAppSelector } from "hooks";
import {
    setDepartAt,
    setFrom,
    setTo,
    tripFilterSelector
} from "./trip-filter-reducer";


export interface TripSearchProps {

}

const TripSearch = forwardRef<HTMLDivElement, TripSearchProps>((_, ref) => {
    const dispatch = useAppDispatch()
    const {
        from,
        to,
        departAt,
    } = useAppSelector(tripFilterSelector)
    const today = useMemo(() => dayjs(), [])

    return (
        <TripSearchRoot ref={ref}>
            <Grid container spacing={1}>
                <Grid item gridArea="location-from" xs={6} lg={4}>
                    <LocationField
                        placeholder="From"
                        value={from}
                        onChange={value => dispatch(setFrom(value))}/>
                </Grid>
                <Grid item gridArea="location-to" xs={6} lg={4}>
                    <LocationField
                        placeholder="To"
                        value={to}
                        onChange={value => dispatch(setTo(value))}/>
                </Grid>
                <Grid item gridArea="depart-at" xs={12} lg={4}>
                    <DateTimeField
                        format="DD/MM/YYYY"
                        minDate={today}
                        value={departAt}
                        onChange={(e) => dispatch(setDepartAt(e || undefined))}
                        sx={{ width: "100%" }}
                    />
                </Grid>
                {/*<Grid item gridArea="seats" xs={3} lg={1}>*/}
                {/*    <TextField*/}
                {/*        name="travelers"*/}
                {/*        placeholder="Travelers"*/}
                {/*        type="number"*/}
                {/*        variant="standard"*/}
                {/*        InputProps={{ inputProps: { min: 1 } }}*/}
                {/*        value={travelers}*/}
                {/*        onChange={(e) => dispatch(setTravelers(parseInt(e.target.value)))}*/}
                {/*        sx={{ width: "100%" }}*/}
                {/*    />*/}
                {/*</Grid>*/}
            </Grid>
        </TripSearchRoot>
    )
})


const TripSearchRoot = styled(Box)<BoxProps>(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    maxWidth: 600,
    margin: "auto",
}))

export default TripSearch