import { isRejected, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";


export interface PagedResult<T> {
    items: T[]
    page: number
    page_size: number
    total: number
}

export interface Account {
    id: string
    username: string
    avatar?: string
    summary: string

    review_stat: {
        review_count: number

        rating: number
        rating_count: number
        rating_sum: number

        positive: number
        negative: number

        tags: Record<string, number>
    }
}

export type Sentiment = "positive" | "neutral" | "negative"

export interface Review {
    id: number;

    account: Account;

    rating: number;
    tags: string[];
    summary: string

    author: Account;
    created_at: string;
    updated_at: string;
}

export interface ReviewCreateCommand {
    accountId: string;

    payload: {
        rating: number;
        tags: string[]
        summary: string
    }
}

export interface ReviewUpdateCommand {
    accountId: string
    reviewId: number

    payload: {
        rating: number;
        tags: string[]
        summary: string
    }
}


export interface MeResponse extends Account {
    username: string
}

export interface Location {
    id: number;
    name: string;
    latitude: number;
    longitude: number;
    ltype: number;
}

export interface LocationSearchQuery {
    query: string;
}

export interface LocationSearchResult {
    result: Location[];
}

export interface TripSearchQuery {
    from: Location
    to: Location
}

export interface Trip {
    id: number
    created_at: string
    updated_at: string
    summary: string
    status: string
    creator: Account
    depart_at: string
    from_location: Location
    to_location: Location
}

export interface TripSearchResult {
    items: Trip[]
    page: number
    per_page: number
    total: number
}

export interface Tag {
    id: number
    type: "review"
    slug: string
}

export const api = createApi({
    reducerPath: "path",
    baseQuery: fetchBaseQuery({}),
    tagTypes: ["reviews", "tag"],
    endpoints: (builder) => ({
        me: builder.query<MeResponse, void>({
            query: () => "/api/account/me",
        }),
        getAccount: builder.query<Account, string>({
            query: (accountId) => `/api/account/${accountId}`,
        }),
        searchLocations: builder.query<LocationSearchResult, LocationSearchQuery>({
            query: (arg) => `/api/location?q=${arg.query}`,
        }),
        getTrip: builder.query<Trip, number>({
            query: tripId => `/api/trip/${tripId}`,
        }),
        getTripsByAccount: builder.query<PagedResult<Trip>, string>({
            query: accountId => `/api/account/${accountId}/trips`
        }),
        searchTrips: builder.query<TripSearchResult, TripSearchQuery>({
            query: (arg) => ({
                url: '/api/trip',
                params: {
                    from_location_id: arg.from.id,
                    to_location_id: arg.to.id,
                }
            })
        }),
        getReview: builder.query<Review, number>({
            query: reviewId => `/api/review/${reviewId}`,
            providesTags: ["reviews"],
        }),
        postReview: builder.mutation<Review, ReviewCreateCommand>({
            query: command => ({
                url: `/api/v1/account/${command.accountId}/reviews`,
                method: "POST",
                body: command.payload,
            }),
            invalidatesTags: ["reviews"],
        }),
        putReview: builder.mutation<Review, ReviewUpdateCommand>({
            query: command => ({
                url: `/api/v1/account/${command.accountId}/reviews/${command.reviewId}`,
                method: "PUT",
                body: command.payload,
            }),
            invalidatesTags: ["reviews"],
        }),
        deleteReview: builder.mutation<void, number>({
            query: reviewId => ({
                url: `/api/v1/review/${reviewId}`,
                method: "DELETE",
            }),
            invalidatesTags: ["reviews"],
        }),
        getReviewsByAccount: builder.query<PagedResult<Review>, string>({
            query: accountId => `/api/account/${accountId}/reviews`,
            providesTags: ["reviews"]
        }),
        searchTags: builder.query<{ result: Tag[] }, { query: string, type: "review" }>({
            query: args => `/api/v1/tag?q=${args.query}&tag_type=${args.type}`,
            providesTags: ["tag"],
        })
    })
})

export const {
    useMeQuery,
    useSearchLocationsQuery,
    useSearchTripsQuery,
    useGetAccountQuery,
    useGetTripQuery,
    useGetTripsByAccountQuery,
    useGetReviewQuery,
    usePostReviewMutation,
    usePutReviewMutation,
    useDeleteReviewMutation,
    useGetReviewsByAccountQuery,
    useSearchTagsQuery,
} = api

/**
 * Cleanup cookies on 401
 */
export const rtkQueryRedirectOn401: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        if (isRejected(action) && action.payload.status === 401) {
            // TODO(souper): logout?
            document.cookie = "X-Going-Merry-Token=; Max-Age:-99999999;"
        }

        return next(action)
    }
