import { useCallback, useState } from "react";

export interface StepOptions<Step> {
    step: Step
    optional?: boolean
    ready?: boolean
}

export interface UseSteps<Step> {
    active: Step;

    isReady: boolean
    isOptional: boolean

    hasNext: boolean
    canSkip: boolean
    canComplete: boolean
    hasPrevious: boolean

    next(): void

    skip(): void

    back(): void
}

export const useSteps = <Step>(steps: StepOptions<Step>[]): UseSteps<Step> => {
    const [activeIdx, setActiveIdx] = useState(0)

    const { step, ready = true, optional = false } = steps[activeIdx]

    const canSkip = optional
    const hasPrevious = activeIdx > 0
    const hasNext = activeIdx < steps.length - 1
    const isReady = ready
    const canComplete = steps.slice(activeIdx).findIndex(x => !x.optional && !x.ready) === -1

    const next = useCallback(() => {
        if (!isReady) {
            throw new Error("Cannot go to next step: active step is not ready")
        }

        if (!hasNext) {
            throw new Error("Cannot go to next step: active step is the last")
        }

        setActiveIdx(activeIdx + 1)

    }, [isReady, hasNext, activeIdx])
    const back = useCallback(() => {

        // Always allow the user to go back2

        if (!hasPrevious) {
            throw new Error("Cannot go to previous step: active is the first")
        }

        setActiveIdx(activeIdx - 1)

    }, [hasPrevious, activeIdx])

    const skip = useCallback(() => {
        if (!canSkip) {
            throw new Error("Cannot skip active step: set optional=true")
        }

        setActiveIdx(activeIdx + 1)

    }, [canSkip, activeIdx])


    return {
        active: step,

        isReady,
        isOptional: optional,

        canSkip,
        canComplete,
        hasPrevious,
        hasNext,

        back,
        next,
        skip,
    }
}