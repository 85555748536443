import React from "react";
import { Provider as StoreProvider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { router } from "router";
import { LightTheme } from "themes";
import { store } from "store";
import 'leaflet/dist/leaflet.css';

import dayjs from "dayjs"
import RelativeTimePlugin from "dayjs/plugin/relativeTime"

dayjs.extend(RelativeTimePlugin)


export const App = () => (
    <ThemeProvider theme={LightTheme}>
        <CssBaseline/>
        <StoreProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <RouterProvider router={router}/>
            </LocalizationProvider>
        </StoreProvider>
    </ThemeProvider>
)
