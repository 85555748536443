import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom"

import { Link as MuiLink, LinkProps as MuiLinkProps, SxProps, Theme } from "@mui/material"

export interface LinkProps {
    to: string
    underline?: MuiLinkProps["underline"]
    variant?: MuiLinkProps["variant"]
    sx?: SxProps<Theme>
    children?: ReactNode
}

export const Link = ({ to, variant, underline = "hover", sx, children }: LinkProps) => {
    return (
        <MuiLink variant={variant} underline={underline}
                 sx={{ color: "inherit", ...sx }}
                 component={RouterLink} to={to}>
            {children}
        </MuiLink>
    )
}
