import { Grid } from "@mui/material";

import { Review } from "api";
import { Timestamp } from "components";
import { AccountAvatar, AccountLink } from "features/account";

import { ReviewCardActions } from "./ReviewCardActions";

export interface ReviewCardHeaderProps {
    review: Review
}

export const ReviewCardHeader = ({ review }: ReviewCardHeaderProps) => {
    return (
        <Grid
            container
            display="grid"
            columnGap={1}
            alignItems="center"
            gridTemplateAreas={`
                "avatar .           actions"
                "avatar username    actions"
                "avatar timestamp   actions"
                "avatar .           actions"
            `}
            gridTemplateColumns="auto 1fr auto"
        >
            <Grid item gridArea="avatar">
                <AccountAvatar account={review.author} size="medium" variant="rounded"/>
            </Grid>

            <Grid item gridArea="username" alignSelf="flex-end">
                <AccountLink account={review.author} variant="h6" sx={{ lineHeight: 1 }}/>
            </Grid>
            <Grid item gridArea="timestamp" alignSelf="flex-start">
                <Timestamp value={review.created_at} TypographyProps={{ lineHeight: 1 }}/>
            </Grid>

            <Grid item gridArea="actions">
                <ReviewCardActions review={review}/>
            </Grid>
        </Grid>
    )
}