import { useRef } from "react";

import { Account, Review, usePostReviewMutation, usePutReviewMutation } from "api";
import { Dialog, ModalProps, ModalRef } from "components/layout";

import { ReviewForm, ReviewFormData } from "./ReviewForm";


export interface ReviewFormDialogProps extends Omit<ModalProps, "children" | "ref"> {
    account: Account
    review?: Review

    onOpen?: () => void;
    onSuccess?: (review: Review) => void
    onCancel?: () => void

    onClose?: () => void
}


export const ReviewFormDialog = ({
    account,
    review,
    onSuccess,
    onCancel,
    onClose,
    ...props
}: ReviewFormDialogProps) => {
    const [postReview] = usePostReviewMutation()
    const [putReview] = usePutReviewMutation()

    const modal = useRef<ModalRef>();

    const close = () => {
        modal.current && modal.current.close()
        onClose?.()
    }

    const handleSubmit = async (data: ReviewFormData) => {
        // TODO(souperk): handle errors
        if (!review) {
            const response = await postReview({
                accountId: account.id,
                payload: data
            }).unwrap()
            onSuccess?.(response)
        } else {
            const response = await putReview({
                accountId: account.id,
                reviewId: review.id,
                payload: data
            }).unwrap()
            onSuccess?.(response)
        }

        close()
    }

    const handleCancel = () => {
        onCancel?.()
        close()
    }

    return (
        <Dialog
            ref={modal}
            width={{ md: theme => theme.breakpoints.values.md }}
            height={600}
            onClose={onClose}
            {...props}
        >
            <ReviewForm
                review={review}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </Dialog>
    )
}

