import { Account } from "api";
import { Link, LinkProps } from "components";


export interface AccountLinkProps extends Omit<LinkProps, "to"> {
    account: Account
}

export const AccountLink = ({ account, variant = "h6", children, ...props }: AccountLinkProps) => {
    return (
        <Link variant={variant} to={`/account/${account.id}`} {...props}>
            {children || account.username}
        </Link>
    )
}