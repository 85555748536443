import { useContext, useEffect, useRef } from "react";

import Leaflet from "leaflet"
import { LeafletContext } from "./LeafletContext";

export interface LeafletTileLayerProps {

}

export const LeafletTileLayer = () => {
    console.log("leaflet: tile layer component")
    const context = useContext(LeafletContext)
    const layer = useRef<Leaflet.Layer>()

    useEffect(() => {
        if (!context.initialized) {
            // wait for the context to be initialized,
            //  no need to return cleanup function because
            //  nothing has been initialized yet
            return
        }

        if (layer.current) {
            // layer has already been initialized but this effect is called,
            //  this is not supposed to happen on production, must be development
            //  mode.
            // Since, the cleanup method has been called, we need to add
            //  this layer on the map and define a new cleanup function.
            console.log("leaflet: tile layer reattachment")
            layer.current?.addTo(context.map)
            return () => {
                console.log("leaflet: tile layer cleanup")
                layer.current?.remove?.()
            }
        }

        console.log("leaflet: tile layer initialization")
        layer.current = new Leaflet.TileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        })
        layer.current.addTo(context.map)

        return () => {
            console.log("leaflet: tile layer cleanup")
            layer.current?.remove?.()
        }
        // Suppress because `context.map` is a static reference.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.initialized, context.map])

    return null
}