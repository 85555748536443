import { Stack, Typography } from "@mui/material";

import { Link } from "components";
import { RatingLike, RatingUtils } from "features/rating";

export interface ReviewFormGuidelineProps {
    rating: RatingLike
}

export const ReviewFormGuideline = ({ rating }: ReviewFormGuidelineProps) => {
    if (RatingUtils.isPositive(rating)) {
        return (
            <Typography variant="body2">
                We are happy you had a safe trip. Please share your experience with others.
            </Typography>

        )
    }

    if (RatingUtils.isNegative(rating)) {
        return (
            <Stack gap={1}>
                <Typography variant="body2">
                    This rating indicates that the user you are review is a safety concern
                    and other users will be advised to avoid riding with them.
                </Typography>
                <Typography variant="body2">
                    If you have been harmed in any way, please review our{" "}
                    <Link to="/safety" underline="always">safety guidelines</Link>.
                </Typography>
            </Stack>
        )
    }

    return (
        <Stack gap={1}>
            <Typography variant="body2">
            </Typography>
        </Stack>
    )
}