import { Badge, Box, Divider, Grid, Stack, Typography } from "@mui/material";

import { Account } from "api";
import { useBreakpoints } from "../../commons/hooks";
import { TagChip } from "../tag";

import { AccountAvatar } from "./AccountAvatar";
import { AccountRatingChip } from "./AccountRatingChip";


export interface AccountProfileProps {
    account: Account
}

export const AccountProfile = ({ account }: AccountProfileProps) => {
    const { breakpoint } = useBreakpoints()
    return (
        <Stack gap={2} direction={{ lg: "row" }}>
            <Box sx={{ padding: { xs: theme => theme.spacing(1, 2), lg: 0 } }}>
                <Grid
                    container
                    rowGap={0.5}
                    columnGap={2}
                    display="grid"
                    gridTemplateAreas={`
                        "avatar ."
                        "avatar username"
                        "avatar chips"
                    `}
                    gridTemplateColumns="auto 1fr"
                    gridTemplateRows="1fr auto auto">
                    <Grid item gridArea="avatar">
                        <AccountAvatar account={account} size="large"/>
                    </Grid>
                    <Grid item gridArea="username">
                        <Typography variant="h4">
                            {account.username}
                        </Typography>
                    </Grid>
                    <Grid item gridArea="chips">
                        <Stack direction="row" gap={1} flexWrap="wrap">
                            <AccountRatingChip account={account} size="small"/>
                            {Object.keys(account.review_stat.tags).map(tag => (
                                <TagChip key={tag} tag={tag} count={account.review_stat.tags[tag]}/>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            <Divider orientation={breakpoint({ lg: "vertical" })} flexItem/>
            <Typography variant="body1">
                {account.summary || (
                    <>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam efficitur ante
                        justo, eu finibus diam porttitor auctor. Nunc a lectus vestibulum, gravida massa
                        in,
                        rutrum dolor. Aliquam eget augue posuere, finibus enim sit amet, dictum dolor.
                        Pellentesque semper sodales nisi, a placerat ipsum. Cras sit amet molestie nisl,
                        in
                        elementum metus. Sed varius consequat fringilla. Sed tincidunt nisl leo, sed
                        volutpat justo condimentum eu. Aliquam ac neque et libero euismod pulvinar.
                    </>
                )}

            </Typography>
        </Stack>
    )
}


