import { Navigate, useParams } from "react-router-dom";

import { useGetAccountQuery } from "api";

import { AccountPage } from "./AccountPage";
import { AccountNotFoundPage } from "./AccountNotFoundPage";

/**
 * This component handles the '/account/:accountId' route.
 *
 */
export const AccountRoute = () => {
    const params = useParams()
    const accountId: string = params.accountId || ""

    // useGetAccountQuery hooks must be invoked before any return statements,
    //  if url param "account" is not defined, the query will be skipped.
    const { isError, data: account } = useGetAccountQuery(accountId, { skip: accountId.length === 0 })

    if (accountId.length === 0) {
        //
        return <Navigate to="/home" replace/>
    }

    if (isError) {
        // TODO(souperk): Error is handled, we render a 404 page on any error.
        return <AccountNotFoundPage/>
    }

    return <AccountPage account={account}/>
}


