import { createTheme } from "@mui/material";

export const DarkTheme = createTheme({
    palette: {
        primary: {
            main: "#037430",
            light: "#06923E",
            dark: "#025E26",
            contrastText: "#fff"
        },
        background: {
            default: "#1E1E1E",
            paper: "#0e9042"
        },
        text: {
            primary: "#fff",
            secondary: "#f5f5f5" //this will come back to bite me in the ass
        }
    }
});
