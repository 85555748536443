import { Skeleton, Stack } from "@mui/material";

import { Trip } from "api";
import { PageHeader, Page, PageContent } from "components";
import { ToolbarLink } from "components/toolbar/ToolbarLink";

import { TripDetails } from "./TripDetails";

export interface TripPageProps {
    trip: Trip | undefined
}

export const TripPage = ({ trip }: TripPageProps) => {
    if (!trip) {
        return (
            <Stack>
                <Skeleton variant="text"/>
            </Stack>
        )
    }

    return (
        <Page>
            <PageHeader toolbarProps={{
                option: <ToolbarLink to="/home" icon="back"/>,
            }}/>
            <PageContent>
                {/*<TripPreview*/}
                {/*    id={tripId}*/}
                {/*    user={trip.user}*/}
                {/*    from={trip.from}*/}
                {/*    to={trip.to}*/}
                {/*    date={trip.date}*/}
                {/*    seats={trip.seats}/>*/}
                <TripDetails/>
            </PageContent>
        </Page>
    )
}

