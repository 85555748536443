import { ReactNode } from "react";

import { Button, Typography } from "@mui/material";

import { useMeQuery } from "api";
import { AccountAvatar } from "features/account";
import { Spacer } from "components";
import { Toolbar } from "components/toolbar";

export interface PageToolbarProps {
    option?: ReactNode
}

export const PageToolbar = ({ option }: PageToolbarProps) => {
    const { isSuccess, data: profile } = useMeQuery()

    return (
        <Toolbar>
            {option || (
                <Spacer flex="0 0 40px"/>
            )}
            <Typography variant="h6" component="span">
                Going Merry
            </Typography>
            <Spacer/>
            {isSuccess && (
                <AccountAvatar account={profile} size="medium"/>
            )}
            {!isSuccess && <Button href="/api/auth/keycloak/login" color="inherit">
                Log in
            </Button>}
        </Toolbar>
    )
}
