import { IconButton, ListItemIcon, MenuItem, MenuList } from "@mui/material";

import { Trip } from "api";
import { Menu } from "components/layout"
import { OptionsIcon, ReportIcon } from "components/icons";

export interface TripCardActionsProps {
    trip: Trip
}

export const TripCardActions = ({ trip }: TripCardActionsProps) => {
    return (
        <Menu
            trigger={
                <IconButton>
                    <OptionsIcon/>
                </IconButton>
            }
        >
            <MenuList>
                <MenuItem>
                    <ListItemIcon>
                        <ReportIcon/>
                    </ListItemIcon>
                    Report
                </MenuItem>
            </MenuList>
        </Menu>
    )
}