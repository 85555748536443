import { Box, Stack, Typography } from "@mui/material";

import { Review } from "api";
import { Rating } from "features/rating";
import { TagChip } from "features/tag";

export interface ReviewCardContentProps {
    review: Review
}

export const ReviewCardContent = ({ review }: ReviewCardContentProps) => {
    return (
        <Stack gap={2}>
            <Stack direction="row" gap={1}>
                <Box sx={{ fontSize: 24 }}>
                    <Rating variant="flat" rating={review.rating} active/>
                </Box>
                {review.tags.map(tag => <TagChip key={tag} tag={tag}/>)}
            </Stack>
            <Typography variant="body1">
                {review.summary}
            </Typography>
        </Stack>
    )
}