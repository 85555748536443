import { IconButton } from "@mui/material";
import { ReactNode } from "react";

import { Link } from "components";
import { ArrowBackIcon, MenuIcon } from "components/icons"

export interface ToolbarLinkProps {
    to: string
    icon?: "menu" | "back" | ReactNode
}

export const ToolbarLink = ({ to, icon }: ToolbarLinkProps) => {
    if (icon === "back") {
        icon = <ArrowBackIcon aria-label="back"/>
    }
    if (icon === "menu") {
        icon = <MenuIcon aria-label="menu"/>;
    }
    return (
        <Link to={to} sx={{ color: "inherit" }}>
            <IconButton>
                {icon}
            </IconButton>
        </Link>
    )
}