import { Button, Stack, Typography } from "@mui/material";

import { Account, useGetReviewsByAccountQuery, useMeQuery } from "api";
import { Spacer } from "components";
import { ReviewCard, ReviewFormDialog } from "features/review";


export interface AccountPageReviewsProps {
    account: Account
}

export const AccountPageReviews = ({ account }: AccountPageReviewsProps) => {
    const { data: profile } = useMeQuery()
    const { data: reviews } = useGetReviewsByAccountQuery(account?.id || "", { skip: !account })

    const isAuthenticated = !!profile

    return (
        <Stack gap={1}>
            <Stack direction="row" alignItems="center" gap={1}>
                <Typography variant="h4">
                    Reviews
                </Typography>
                <Spacer/>
                {isAuthenticated && (
                    <ReviewFormDialog
                        account={account}
                        trigger={<Button>Write a review</Button>}
                    />
                )}
            </Stack>

            {/* TODO(souperk): Add search functionality for reviews */}
            {/* TODO(souperk): Support filtering reviews by rating */}
            {(reviews?.items || []).map(review => <ReviewCard key={review.id} review={review}/>)}
        </Stack>
    )
}