import { alpha, Typography } from "@mui/material";
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent, timelineOppositeContentClasses,
    TimelineSeparator
} from "@mui/lab";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import { Trip, Location } from "api"

export interface TripTimelineProps {
    trip: Trip
}


export const TripTimeline = ({ trip }: TripTimelineProps) => {
    const stops = [trip.from_location, trip.to_location]
    const first = stops[0]
    const last = stops[stops.length - 1]

    const renderStop = (stop: Location) => {
        const isFirst = stop === first
        const isLast = stop === last
        const isMiddle = !isFirst && !isLast
        return (
            <TimelineItem key={stop.id}>
                <TimelineOppositeContent>
                    {!isMiddle && (
                        <Typography variant="caption">
                            11:00 AM
                        </Typography>
                    )}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    {isFirst && (
                        <TimelineDot sx={{
                            padding: "4px",
                            backgroundColor: theme => theme.palette.primary.main,
                        }}
                        >
                            <DirectionsCarIcon sx={{ fontSize: "16px" }}/>
                        </TimelineDot>
                    )}
                    {isMiddle && (
                        <TimelineDot sx={{
                            marginX: "8px",
                            backgroundColor: theme => alpha(theme.palette.common.black, 0.2),
                        }}/>
                    )}
                    {isLast && (
                        <TimelineDot sx={{
                            padding: "4px",
                            backgroundColor: theme => theme.palette.primary.main,
                        }}
                        >
                            <LocationOnIcon sx={{ fontSize: "16px" }}/>
                        </TimelineDot>
                    )}
                    {!isLast && (
                        <TimelineConnector sx={{
                            backgroundColor: theme => alpha(theme.palette.common.black, 0.12),
                        }}/>
                    )}
                </TimelineSeparator>
                {!isMiddle && (
                    <TimelineContent>
                        <Typography variant="h6">
                            {stop.name}
                        </Typography>
                    </TimelineContent>
                )}
                {isMiddle && (
                    <TimelineContent>
                        <Typography variant="caption">
                            {stop.name}
                        </Typography>
                    </TimelineContent>
                )}
            </TimelineItem>
        )
    }
    return (
        <Timeline
            sx={{
                padding: 0,
                margin: 0,
                ".MuiTimelineOppositeContent-root": {
                    padding: theme => theme.spacing(1, 2, 1, 0)
                },
                ".MuiTimelineContent-root": {
                    padding: theme => theme.spacing(1, 0, 1, 2)
                },
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: "0 0 71px",
                },
            }}
        >
            {stops.map(renderStop)}
        </Timeline>
    )
}

