export type RatingLike = number | "positive" | "negative" | null

const isEmpty = (value: RatingLike) => value !== null && value === 0
const isPositive = (value: RatingLike) => value !== null && (value > 0 || value === "positive")
const isNegative = (value: RatingLike) => value !== null && (value < 0 || value === "negative")


export interface FormatOptions {
    variant?: "approval" | "five-star"
}

function format(value: RatingLike, {
    variant = "approval"
}: FormatOptions = {}) {
    if (value === null) {
        value = 0
    }
    if (value === "positive") {
        value = 1
    }
    if (value === "negative") {
        value = -1
    }

    switch (variant) {
        case "five-star":
            const fiveStar = (value + 1) / 2 * 5
            return fiveStar.toLocaleString("en-US", {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            })
        default:
        case "approval":
            const approval = (value + 1) / 2 * 100
            return approval.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }) + "%"
    }
}

export function asString(value: RatingLike): "positive" | "negative" | null {
    if (isPositive(value)) {
        return "positive"
    }
    if (isNegative(value)) {
        return "negative"
    }
    return null
}

export function asNumber(value: RatingLike): number {
    if (typeof value === "number") {
        return value
    }

    if (isPositive(value)) {
        return 1
    }
    if (isNegative(value)) {
        return -1
    }
    return 0
}


export const RatingUtils = {
    empty: null,
    positive: "positive",
    negative: "negative",

    isEmpty,
    isPositive,
    isNegative,

    format,

    asString,
    asNumber,
}
