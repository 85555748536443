import { Card, CardContent, Divider } from "@mui/material";

import { Review } from "api";

import { ReviewCardContent } from "./ReviewCardContent";
import { ReviewCardHeader } from "./ReviewCardHeader";


export interface ReviewCardProps {
    review: Review;
}

export const ReviewCard = ({ review }: ReviewCardProps) => {
    return (
        <Card
            sx={{
                ".MuiCardContent-root,.MuiCardContent-root:last-child": theme => ({
                    xs: { padding: theme.spacing(1) },
                    lg: { padding: theme.spacing(1, 2) }
                })
            }}
        >
            <CardContent>
                <ReviewCardHeader review={review}/>
            </CardContent>
            <Divider/>
            <CardContent>
                <ReviewCardContent review={review}/>
            </CardContent>
        </Card>
    )
}



