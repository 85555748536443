import {
    cloneElement,
    ForwardedRef,
    forwardRef,
    MouseEvent,
    ReactElement,
    ReactNode,
    useImperativeHandle,
    useState
} from "react";

import { Drawer, Menu as MuiMenu } from "@mui/material"

import { useBreakpoints } from "commons/hooks";


export interface MenuRef {
    isOpen: boolean
    close: () => void
}

export interface MenuTriggerProps {
    onClick?: (event: MouseEvent<HTMLElement>) => void
}

export interface MenuProps {
    trigger?: ReactElement<MenuTriggerProps>

    children?: ReactNode
}

export const Menu = forwardRef(
    function ({ trigger, children }: MenuProps, ref: ForwardedRef<MenuRef | undefined>) {
        const { breakpoint } = useBreakpoints()
        const [anchor, setAnchor] = useState<null | HTMLElement>(null)

        useImperativeHandle(ref, () => ({
            isOpen: !!anchor,
            close: () => {
                setAnchor(null)
            },
        }))

        const handleTriggerClick = (event: MouseEvent<HTMLElement>) => {
            setAnchor(event.currentTarget)
        }
        const handleClose = () => {
            setAnchor(null)
        }

        const menu = breakpoint({
            xs: (
                <Drawer open={!!anchor} anchor="bottom" onClose={handleClose}>
                    {children}
                </Drawer>
            ),
            lg: (
                <MuiMenu
                    open={!!anchor} onClose={handleClose} anchorEl={anchor}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right"
                    }}
                >
                    {children}
                </MuiMenu>
            ),
        })

        return (
            <>
                {trigger && cloneElement(trigger, { onClick: handleTriggerClick })}
                {menu}
            </>
        )

    }
)