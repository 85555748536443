import { Card, CardContent, CardHeader, CardProps, styled } from "@mui/material"


export const TripDetails = () => {
    return (
        <TripDetailsCard>
            <CardHeader title="Trip Details"/>
            <CardContent>
                Lorem ipsum dolor sit amet.
            </CardContent>
        </TripDetailsCard>
    )
}

const TripDetailsCard = styled(Card)<CardProps>(({ theme }) => ({
    "& > .MuiCardHeader-root, & > .MuiCardContent-root, & > .MuiCardContent-root:last-child": {
        padding: theme.spacing(1)
    },
}))