import { BreakpointFn, MapBreakpointOptionsFn, useBreakpoints } from "./useBreakpoints";

export type DeviceBreakpoint = "mobile" | "desktop"

export interface UseDeviceBreakpoints {
    breakpoints: BreakpointFn<DeviceBreakpoint>
}

export const useAppBreakpoints = () => useBreakpoints(((options = {
    mobile: "mobile",
    desktop: "desktop"
}) => {
    return {
        xs: options.mobile,
        sm: options.mobile,
        md: options.mobile,
        lg: options.desktop,
        xl: options.desktop,
    }
}) as MapBreakpointOptionsFn<DeviceBreakpoint>);

// export function useDeviceBreakpointValue<T>(options: BreakpointOptions<AppBreakpoint, T> = {}) {
//     const { breakpoint } = useDeviceBreakpoints()
//     return breakpoint(options)
// }
