import { alpha, Chip, ChipProps, SxProps, Theme } from "@mui/material";

import { Account } from "api";
import { RatingIcon, RatingLike, RatingUtils } from "features/rating";
import { formatReviewCount } from "features/review/utils";


export interface AccountRatingChipProps {
    account: Account
    size?: ChipProps["size"]
}

export const AccountRatingChip = ({ account, size }: AccountRatingChipProps) => {
    const chipLabel = `${RatingUtils.format(account.review_stat.rating)} (${formatReviewCount(account.review_stat.review_count)})`
    return (
        <Chip
            icon={<RatingIcon rating={account.review_stat.rating}/>}
            label={chipLabel} size={size}
            sx={{ ...chipColors(account.review_stat.rating) }}
        />
    )
}

function chipColors(rating: RatingLike): SxProps<Theme> {

    // TODO(souperk): Create styled component
    const chipSx = (starColor: (theme: Theme) => string, bgColor?: (theme: Theme) => string): SxProps<Theme> => {
        bgColor = (bgColor || starColor)
        return {
            backgroundColor: theme => alpha((bgColor as (theme: Theme) => string)(theme), 0.08),
            // Change ONLY the color for the star
            ".MuiChip-icon.MuiSvgIcon-root": {
                color: theme => starColor(theme),
            },

        }
    }

    if (RatingUtils.isPositive(rating)) {
        return chipSx(theme => theme.palette.success.main)
    }
    if (RatingUtils.isNegative(rating)) {
        return chipSx(theme => theme.palette.error.main)
    }
    return chipSx(theme => theme.palette.warning.main)

    // return chipSx(
    //     // mui/material-ui@packages/mui-material/src/Chip/Chip.js#L89
    //     theme => theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[300],
    //     // Default value as defined on:
    //     // mui/material-ui@packages/mui-material/src/Chip/Chip.js#L90
    //     theme => theme.palette.action.selected,
    // )
}