import { ForwardedRef, forwardRef, MouseEvent, useImperativeHandle, useRef } from "react";

import { SystemCssProperties } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Box, Paper, Theme } from "@mui/material";

import { Modal, ModalProps, ModalRef } from "./Modal";

export interface DialogProps extends ModalProps {
    position?: "center" | "bottom" | "top"
    width?: SystemCssProperties<Theme>["width"]
    height?: SystemCssProperties<Theme>["height"]
}


export const Dialog = forwardRef(
    function Dialog({
        position = "center",
        width,
        height,
        children,
        ...props
    }: DialogProps, ref: ForwardedRef<ModalRef | undefined>) {
        const modal = useRef<ModalRef>()
        useImperativeHandle(ref, () => modal.current)
        const handleOverlayClick = (e: MouseEvent<HTMLDivElement>) => {
            if (e.target !== e.currentTarget) {
                return
            }
            modal.current?.close?.()
        }

        return (
            <Modal ref={modal} {...props}>
                <Box
                    onClick={handleOverlayClick}
                    sx={{
                        width: "100%",
                        height: "100%",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Paper sx={{
                        width: width,
                        height: "100%",
                        maxHeight: height,
                        flex: { xs: 1, sm: "0 0 420px" },
                        margin: { sm: theme => theme.spacing(2, 4) },
                        overflow: "auto",
                    }}>
                        <Box sx={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}>
                            <Box sx={{
                                width: "100%",
                                height: "100%",
                                maxHeight: height,
                                margin: "auto",
                            }}>
                                {children}
                            </Box>
                        </Box>
                    </Paper>
                </Box>
            </Modal>
        )
    }
)
