import { Navigate, useParams } from "react-router-dom";

import { useGetTripQuery } from "api";

import { TripPage } from "./TripPage";
import { TripNotFoundPage } from "./TripNotFoundPage";

export const TripRoute = () => {
    const params = useParams()
    const tripId = (params.tripId && Number.parseInt(params.tripId)) || -1

    const { isError, data: trip } = useGetTripQuery(tripId, { skip: tripId === -1 })

    if (tripId === -1) {
        return <Navigate to="/home" replace/>
    }

    if (isError) {
        // TODO(souperk): Implement TripNotFoundPage
        return <TripNotFoundPage/>
    }

    return <TripPage trip={trip}/>
}