import { Stack, Typography } from "@mui/material";

import { Account, useGetTripsByAccountQuery } from "api";
import { TripCard } from "features/trip";


export interface AccountPageTripsProps {
    account: Account
}

export const AccountPageTrips = ({ account }: AccountPageTripsProps) => {
    const { data: trips } = useGetTripsByAccountQuery(account?.id || "", { skip: !account })

    return (
        <Stack gap={1}>
            <Typography variant="h4">
                Trips
            </Typography>
            {(trips?.items || []).map(trip => <TripCard key={trip.id} trip={trip}/>)}
        </Stack>

    )
}