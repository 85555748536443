import { Skeleton, Stack } from "@mui/material";


import { Account } from "api";
import { PageHeader, Page, PageContent } from "components";
import { ToolbarLink } from "components/toolbar";
import { AccountProfile } from "features/account/AccountProfile";
import { SafetyDisclaimer } from "features/account-page/SafetyDisclaimer";

import { AccountPageReviews } from "./AccountPageReviews";
import { AccountPageTrips } from "./AccountPageTrips";

interface AccountPageProps {
    account?: Account
}

export const AccountPage = ({ account }: AccountPageProps) => {
    if (!account) {
        return <AccountPageSkeleton/>
    }

    return (
        <Page>
            <PageHeader toolbarProps={{
                option: <ToolbarLink to="/home" icon="back"/>,
            }}/>
            <PageContent>
                <Stack gap={4} sx={{ maxWidth: "lg", margin: "auto" }}>
                    <Stack gap={1}>
                        <AccountProfile account={account}/>
                        <SafetyDisclaimer level="warning"/>
                    </Stack>

                    <AccountPageReviews account={account}/>
                    <AccountPageTrips account={account}/>
                </Stack>
            </PageContent>
        </Page>
    )
}

const AccountPageSkeleton = () => {
    return (
        <Stack>
            <Skeleton variant="text"/>
        </Stack>
    )
}

