import { alpha } from "@mui/system";
import { SxProps, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";

import { Rating } from "./Rating";

export type RatingToggleProps = {
    rating?: "positive" | "negative" | null
    mode?: "toggle"
    value?: boolean
    onChange?: (value: boolean) => void

    sx?: SxProps<Theme>
}

export const RatingToggle = ({
    mode = "toggle",
    rating = null,
    value = false,
    onChange,
    sx = {}
}: RatingToggleProps) => {
    const handleSelection = () => {
        if (mode === "toggle") {
            onChange?.(!value)
        }
    }

    return (
        <Rating
            rating={rating}
            active={value}
            onClick={handleSelection}
            sx={{
                cursor: "pointer",
                ...sx,

                active: {
                    color: grey[600],
                    borderColor: grey[600],
                    backgroundColor: alpha(grey[600], 0.12),

                    positive: {
                        color: (theme: Theme) => theme.palette.success.main,
                        borderColor: (theme: Theme) => theme.palette.success.main,
                        backgroundColor: (theme: Theme) => alpha(theme.palette.success.main, 0.08),
                    },

                    negative: {
                        color: "error.main",
                        borderColor: "error.main",
                        backgroundColor: (theme: Theme) => alpha(theme.palette.error.main, 0.08),
                    }
                },
            }}
        />
    )
}
