import { Alert } from "@mui/material";

export interface SafetyDisclaimerProps {
    level: "info" | "warning" | "error"
}

export const SafetyDisclaimer = ({ level }: SafetyDisclaimerProps) => {
    if (level === "warning") {
        return (
            <Alert severity="warning">
                This user has a rating below 2/5! Be extra careful when arranging
                a ride with them, it may not be safe.
            </Alert>
        )
    }

    return null
}
