export function formatReviewCount(count: number | null | undefined) {
    if (count === undefined || count === null) {
        return count
    }
    const formatter = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 3,
        notation: "compact",
    })
    return formatter.format(count)
}


// TODO(souperk): Should we move rating severity to the api?
export type SafetyIndicator = "caution-advised" | "unpleasant" | "safe"

export function ratingToSafetyIndicator(rating: number): SafetyIndicator {
    if (rating < 2) {
        return "caution-advised"
    }
    if (rating < 3) {
        return "unpleasant"
    }
    return "safe"
}

