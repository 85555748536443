import { useRef } from "react";

import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";

import { Review, useMeQuery } from "api";
import { Menu, MenuRef } from "components/layout"
import { DeleteIcon, EditIcon, OptionsIcon, ReportIcon } from "components/icons";
import { MessageDialog } from "../../components/layout/MessageDialog";
import { ReviewDeleteDialog } from "./ReviewDeleteDialog";

import { ReviewFormDialog } from "./ReviewFormDialog";

export interface ReviewCardActionsProps {
    review: Review
}

export const ReviewCardActions = ({ review }: ReviewCardActionsProps) => {
    const menu = useRef<MenuRef>()
    const { data: profile } = useMeQuery()

    const close = () => {
        console.log("closing review card actions")
        menu.current?.close?.()
    }

    const isAuthor = review.author.id === profile?.id

    return (
        <Menu
            ref={menu}
            trigger={
                <IconButton>
                    <OptionsIcon/>
                </IconButton>
            }
        >
            <MenuList sx={{ width: "100%", minWidth: 320 }}>
                <MessageDialog
                    title="Report review"
                    message="Reports are not implemented at this moment."
                    onClose={() => {
                        console.log("closing menu")
                        close()
                    }}
                    trigger={
                        <MenuItem>
                            <ListItemIcon>
                                <ReportIcon/>
                            </ListItemIcon>
                            <ListItemText>
                                Report
                            </ListItemText>
                        </MenuItem>
                    }
                />
                {isAuthor && (
                    <ReviewFormDialog
                        review={review}
                        account={review.account}
                        onClose={close}
                        trigger={
                            <MenuItem>
                                <ListItemIcon>
                                    <EditIcon/>
                                </ListItemIcon>
                                <ListItemText>Edit</ListItemText>
                            </MenuItem>
                        }/>
                )}
                {isAuthor && (
                    <ReviewDeleteDialog
                        review={review}
                        onClose={close}
                        trigger={
                            <MenuItem>
                                <ListItemIcon>
                                    <DeleteIcon/>
                                </ListItemIcon>
                                <ListItemText>Delete</ListItemText>
                            </MenuItem>
                        }/>
                )}
            </MenuList>
        </Menu>
    )
}