import { forwardRef, ReactNode } from 'react';

import { alpha } from "@mui/system"
import { AppBar, Divider } from "@mui/material";

import { PageToolbar, PageToolbarProps } from "./PageToolbar";

export interface PageHeaderProps {
    children?: ReactNode
    toolbar?: ReactNode
    toolbarProps?: PageToolbarProps
}


export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({
    children,
    toolbar,
    toolbarProps
}, ref) => {
    return (
        <AppBar ref={ref} position="static"
                sx={{
                    px: 1,
                    ".MuiFormControl-root": {
                        color: "white",
                        borderColor: "white",
                    },
                    ".MuiInputAdornment-root, .MuiButtonBase-root": {
                        color: "white",
                    },
                    ".MuiDivider-root": {
                        borderColor: theme => alpha(theme.palette.primary.contrastText, 0.12),
                    },
                    ".MuiInputBase-root": {
                        color: "white",
                    },
                    ".MuiInputBase-root label.Mui-focused": {
                        color: "white"
                    },
                    ".MuiInput-underline:before, .MuiInput-underline:hover:before": {
                        borderBottomColor: theme => theme.palette.primary.contrastText,
                    },
                    ".MuiInput-underline:after": {
                        borderBottomColor: theme => theme.palette.primary.main,
                    },
                    ".MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: theme => alpha(theme.palette.primary.contrastText, 0.12),
                        },
                        "&:hover fieldset": {
                            borderColor: theme => alpha(theme.palette.primary.contrastText, 0.12),
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: theme => theme.palette.primary.contrastText,
                        }
                    },
                }}>
            {toolbar || <PageToolbar {...toolbarProps}/>}
            {(!!children) && (
                <>
                    <Divider/>
                    {children}
                </>
            )}
        </AppBar>
    )
})

