import { Card, CardContent, Divider } from "@mui/material";

import { Trip } from "api";
import { TripCardContent } from "./TripCardContent";
import { TripCardHeader } from "./TripCardHeader";


export interface TripCardProps {
    trip: Trip
}

export const TripCard = ({ trip }: TripCardProps) => {
    return (
        <Card
            sx={{
                ".MuiCardContent-root,.MuiCardContent-root:last-child": theme => ({
                    xs: {
                        padding: theme.spacing(1),
                    },
                    lg: {
                        padding: theme.spacing(2, 4),
                    }
                })
            }}
        >
            <CardContent>
                <TripCardHeader trip={trip}/>
            </CardContent>
            <Divider/>
            <CardContent>
                <TripCardContent trip={trip}/>
            </CardContent>
        </Card>
    )
}


