import { ReactNode, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";

import Leaflet from "leaflet";
import { LeafletContext, LeafletUninitializedContext } from "./LeafletContext";


export interface LeafletMapProps {
    children?: ReactNode
}

export const LeafletMap = ({ children }: LeafletMapProps) => {
    console.log("leaflet: map component")

    // Generate a unique id for the map instance
    const mapId = useMemo(() => {
        const key = crypto.randomUUID()
        return `leaflet-map-container-${key}`
    }, [])

    const [context, setContext] = useState(LeafletUninitializedContext)

    useEffect(() => {
        if (context.initialized) {
            // SHOULD NEVER HAPPEN
            console.error("context is already initialized")
        }

        console.log("leaflet: map initialization");
        const map = new Leaflet.Map(mapId);
        map.setView([38.5, 22], 5)
        setContext({
            initialized: true,
            map
        })

        return () => {
            console.log("leaflet: map cleanup");
            map.remove()
            // Reset the context state, there is no way to reinsert the map.
            setContext(LeafletUninitializedContext)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapId])

    // Make sure the map resizes when there is a window resize
    useEffect(() => {
        if (!context.initialized) {
            // wait for the context to be initialized.
            return
        }

        const handleResize = () => {
            context.map?.invalidateSize()
        }


        // TODO(souperk): Investigate the bug described below.
        // There is a bug where flex/grid layouts take a moment to
        //  finalize. We use a timeout to invoke map.invalidateSize()
        //  which renders that map to the correct size.
        const timeout = window.setTimeout(() => {
            handleResize()
        }, 2000)

        window.addEventListener("load", handleResize)
        window.addEventListener("resize", handleResize)
        return () => {
            window.clearTimeout(timeout)
            window.removeEventListener("load", handleResize)
            window.removeEventListener("resize", handleResize)
        }
    }, [context])


    return (
        <LeafletContext.Provider value={context}>
            <Box style={{ height: "100%", width: "100%" }} id={mapId}>
                {children}
            </Box>
        </LeafletContext.Provider>
    )
}