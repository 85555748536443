import { Typography, TypographyProps } from "@mui/material";
import dayjs from "dayjs";


export interface TimestampProps {
    value: string

    TypographyProps?: TypographyProps
}

export const Timestamp = ({ value, TypographyProps }: TimestampProps) => {
    const formatted = dayjs(value).fromNow()
    return (
        <Typography component="span" variant="caption" {...TypographyProps}>
            {formatted}
        </Typography>
    )
}