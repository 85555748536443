import { Button, Paper, Stack, Typography } from "@mui/material";
import { ForwardedRef, forwardRef, ReactNode, useImperativeHandle, useRef } from "react";
import { Spacer } from "../Spacer";
import { Dialog, DialogProps } from "./Dialog";
import { ModalRef } from "./Modal";

export interface DeleteDialogProps extends Omit<DialogProps, "title"> {
    title?: ReactNode
    message?: ReactNode

    onCancel?: () => void
    onConfirm?: () => void
}

export const DeleteDialog = forwardRef(
    function DeleteDialog({
        title = "Are you sure?",
        message = "Are you sure?",
        onCancel,
        onConfirm,
        ...props
    }: DeleteDialogProps, ref: ForwardedRef<ModalRef | undefined>) {
        const dialog = useRef<ModalRef>()
        useImperativeHandle(ref, () => dialog.current)

        if (typeof title === "string") {
            // wrap title with Typography
            title = (
                <Typography variant="h6">
                    {title}
                </Typography>
            )
        }

        if (typeof message === "string") {
            message = (
                <Typography variant="body1">
                    {message}
                </Typography>
            )
        }

        return (
            <Dialog ref={dialog} width={320} height={200} {...props}>
                <Stack height="100%">
                    <Paper square elevation={0} sx={{
                        padding: theme => theme.spacing(1, 2),
                        bgcolor: "grey.200",
                    }}>
                        {title}
                    </Paper>

                    <Spacer sx={{ padding: theme => theme.spacing(1, 2) }}>
                        {message}
                    </Spacer>

                    <Paper square elevation={0} sx={{
                        padding: theme => theme.spacing(1, 2),
                        bgcolor: "grey.200",
                    }}>
                        <Stack direction="row">
                            <Button color="inherit" sx={{ flex: 1 }}
                                    onClick={onCancel}>
                                Cancel
                            </Button>
                            <Button color="error" sx={{ flex: 1 }} onClick={onConfirm}>
                                Delete
                            </Button>
                        </Stack>
                    </Paper>
                </Stack>
            </Dialog>
        )
    }
)