import { useState } from "react";

import { Box, Button, Paper, Stack, TextField, Typography, } from "@mui/material";

import { Review } from "api";
import { useSteps } from "commons/hooks";
import { Spacer } from "components";
import { RatingLike, RatingUtils } from "features/rating";

import { ReviewFormRating } from "./ReviewFormRating";
import { ReviewFormTags } from "./ReviewFormTags";


export type ReviewFormData = Pick<Review, "rating" | "tags" | "summary">

export interface ReviewFormProps {
    review?: Review;

    onSubmit?: (data: ReviewFormData) => void
    onCancel?: () => void
}

export const ReviewForm = ({ review, onSubmit, onCancel }: ReviewFormProps) => {

    const [rating, setRating] = useState<number>(review?.rating || 0)
    const [tags, setTags] = useState<string[]>(review?.tags || [])
    const [summary, setSummary] = useState(review?.summary || "")

    const { active: activeStep, isReady, isOptional, hasNext, next, canSkip, skip, hasPrevious, back } = useSteps([
        {
            step: {
                key: "rating",
                title: "Review: Rating",
            }, ready: rating !== null
        },
        {
            step: {
                key: "tags",
                title: "Review: Tags",
            }, optional: true
        },
        {
            step: {
                key: "summary",
                title: "Review: Summary",
            }, optional: true
        }
    ])

    const handleSubmit = () => {
        onSubmit?.({
            rating,
            tags,
            summary
        })
    }

    const handleRatingToggle = (newRating: RatingLike) => {
        if (RatingUtils.isPositive(newRating) && !RatingUtils.isPositive(rating)) {
            setRating(1)
        } else if (RatingUtils.isNegative(newRating) && !RatingUtils.isNegative(rating)) {
            setRating(-1)
        } else {
            setRating(0)
        }
    }
    const handleTagSelect = (tag: string) => {
        const idx = tags.indexOf(tag)
        if (idx === -1) {
            setTags([...tags, tag])
        } else {
            setTags(tags.filter(x => x !== tag))
        }
    }

    return (
        <Stack sx={{ height: "100%" }}>
            <Paper square elevation={0} sx={{
                padding: theme => theme.spacing(1, 2),
                bgcolor: "grey.200",
            }}>
                <Typography variant="h4">
                    {activeStep.title}
                </Typography>
            </Paper>

            <Box sx={{
                flex: "1",
                overflow: "auto",
                padding: theme => theme.spacing(1, 2),
            }}>
                {activeStep.key === "rating" && (
                    <ReviewFormRating
                        rating={rating}
                        onPositive={() => handleRatingToggle("positive")}
                        onNegative={() => handleRatingToggle("negative")}
                    />
                )}

                {activeStep.key === "tags" && (
                    <ReviewFormTags tags={tags} onSelect={handleTagSelect}/>
                )}

                {activeStep.key === "summary" && (
                    <TextField
                        placeholder="Summary"
                        multiline rows={4}
                        value={summary}
                        onChange={(event) => setSummary(event.target.value)}
                        sx={{ width: "100%" }}
                    />
                )}
            </Box>

            <Paper square elevation={0} sx={{
                padding: theme => theme.spacing(1, 2),
                bgcolor: "grey.200",
            }}>
                <Stack direction="row" gap={2}>
                    {!hasPrevious && (
                        <Button color="inherit" onClick={onCancel}>Cancel</Button>
                    )}
                    {hasPrevious && (
                        <Button color="inherit" onClick={back}>Back</Button>
                    )}
                    <Spacer/>
                    {hasNext && canSkip && !isOptional && (
                        <Button onClick={skip}>Skip</Button>
                    )}
                    {hasNext && (
                        <Button onClick={next} disabled={!isReady}>Next</Button>
                    )}
                    {!hasNext && (
                        <Button onClick={handleSubmit}>Complete</Button>
                    )}
                </Stack>
            </Paper>
        </Stack>
    )
}


