import { useState } from "react";

import { Autocomplete, CircularProgress, InputAdornment, SxProps, TextField, Theme } from "@mui/material";
import PublicIcon from '@mui/icons-material/Public';

import { Location, useSearchLocationsQuery } from "api";


export interface LocationFieldProps {
    placeholder: string
    value: Location | null
    onChange: (value: Location | null) => void
    sx?: SxProps<Theme>
}

export const LocationField = ({ placeholder, value, onChange, sx }: LocationFieldProps) => {
    const [query, setQuery] = useState("")
    const { isLoading, data } = useSearchLocationsQuery({ query }, { skip: query.length < 2 })

    const [open, setOpen] = useState(false)

    let options = (data?.result || []) as Location[]

    // TODO(souperk): The cleanIndicator (x button) uses `visibility: hidden`
    //  instead of `display: none`. This is an issue because on mobile screens
    //  it occupies more space than necessary.
    return (
        <Autocomplete
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={option => option.name}
            noOptionsText="No matches found!"
            options={options}
            loading={isLoading}
            renderOption={(props, option) => {
                return (
                    <li {...props} key={option.id}>
                        {option.name}
                    </li>
                )
            }}
            onInputChange={(event, newInputValue) => {
                setQuery(newInputValue)
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <>
                                {!value && (
                                    <InputAdornment position="start">
                                        <PublicIcon/>
                                    </InputAdornment>
                                )}
                                {params.InputProps.startAdornment}
                            </>
                        ),
                        endAdornment: (
                            isLoading
                                ? <CircularProgress color="inherit" size={20}/>
                                :
                                params.InputProps.endAdornment
                        ),
                    }
                    }
                />)}
            sx={sx}
        />
    )
}