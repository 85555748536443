import { createTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

export const LightTheme = createTheme({
    palette: {
        primary: {
            main: "#037430",
            light: "#AFDEC2",
            dark: "#036D2E"
        },
        background: {
            // default: "white",
            default: grey[100],
            // paper: "#DBFFEA"
            paper: "white"
        },
    }
});
