import { Grid, Stack, Typography } from "@mui/material";
import { LeafletMap, LeafletRoutingControl, LeafletTileLayer } from "components/leaflet-map";

import { Location } from "api";


export interface TripMapProps {
    stops: Location[];
}

export const TripMap = ({ stops }: TripMapProps) => {
    const waypoints: [number, number][] = stops.map((location) =>
        [location.longitude, location.latitude]
    )
    return (
        <Stack direction="column" gap={2}
               sx={{ height: "100%" }}>
            < Typography variant="h4" component="div">
                Map
            </Typography>
            <Grid item flex={1}>
                <LeafletMap>
                    <LeafletTileLayer/>
                    <LeafletRoutingControl coords={waypoints}/>
                </LeafletMap>
            </Grid>
        </Stack>

    )
}

