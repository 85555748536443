import { Chip, ChipProps } from "@mui/material";
import { alpha } from "@mui/system";

import { CloseIcon, TagIcon } from "components/icons";


export interface TagChipProps {
    tag: string
    count?: number
    selected?: boolean
    onClick?: () => void
    size?: ChipProps["size"]
}

export const TagChip = ({ tag, count = 0, selected = false, onClick, size = "small" }: TagChipProps) => {
    let label = tag
    if (count > 0) {
        label = `${tag} (${count})`
    }
    return (
        <Chip
            icon={selected ? <CloseIcon/> : <TagIcon/>}
            label={label}
            color={selected ? "primary" : undefined}
            size={size}
            onClick={onClick}
            sx={{
                // fontSize: theme => theme.typography.body1.fontSize,
                ":hover": {
                    color: selected ? (theme => theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[300])
                        : "primary.main",
                    backgroundColor: selected ? "action.hover"
                        : theme => alpha(theme.palette.primary.main, 0.08),
                    ".MuiSvgIcon-root": {
                        color: selected ? (theme => theme.palette.mode === 'light' ? theme.palette.grey[700] : theme.palette.grey[300])
                            : "primary.main",
                    },
                },
            }}
        />
    )
}
