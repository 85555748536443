import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";

import { api, rtkQueryRedirectOn401 } from "./api";

import tripReducer from "features/trip-search/reducer"


export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        trip: tripReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware, rtkQueryRedirectOn401)
})

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch