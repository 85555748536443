import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import { RootState } from "store";
import { Location } from "api"


interface TripFilterState {
    from: Location | null
    to: Location | null
    departAt?: string
}

// const initialState: TripFilterState = { from: null, to: null }
// TODO(souperk): Restore empty initial state
const initialState: TripFilterState = {
    from: {
        id: 1,
        latitude: 23.7348324,
        longitude: 37.9755648,
        ltype: 1,
        name: 'Αθήνα'
    },
    to: {
        id: 28,
        latitude: 22.9352716,
        longitude: 40.6403167,
        ltype: 1,
        name: 'Θεσσαλονίκη'
    },
}

const slice = createSlice({
    name: "filter",
    initialState: initialState,
    reducers: {
        setFrom(state, action: PayloadAction<Location | null>) {
            state.from = action.payload;
        },
        setTo(state, action: PayloadAction<Location | null>) {
            state.to = action.payload;
        },
        setDepartAt: {
            reducer(state, action: PayloadAction<string | undefined>) {
                state.departAt = action.payload
            },
            prepare(date: Dayjs | undefined) {
                return {
                    payload: date?.toISOString()
                }
            }
        }
    }
})

export const {
    setFrom,
    setTo,
    setDepartAt,
} = slice.actions
export default slice.reducer


export const tripFilterSelector = createSelector((state: RootState) => state.trip.filter, filter => {
    return {
        ...filter,
        departAt: dayjs(filter.departAt),
    }
})