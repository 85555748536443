import { useRef } from "react";

import { Review, useDeleteReviewMutation } from "api";
import { DeleteDialog, DialogProps, ModalRef } from "components/layout";

export interface ReviewDeleteDialogProps extends Omit<DialogProps, "ref"> {
    review: Review
}


export const ReviewDeleteDialog = ({ review, ...props }: ReviewDeleteDialogProps) => {
    const dialog = useRef<ModalRef>()
    const [deleteReview] = useDeleteReviewMutation()

    const handleDelete = async () => {
        console.log(`dispatching review delete command (${review.id})`)

        try {
            await deleteReview(review.id).unwrap()
        } catch (error) {
            console.error(`review delete command (${review.id}): request failed`)
            console.error(error)
        }

        console.log("closing dialog")
        dialog.current?.close?.()
    }


    return (
        <DeleteDialog
            ref={dialog}
            title="Confirm deletion"
            message="Are you sure you want to delete your review?"
            onCancel={dialog.current?.close}
            onConfirm={handleDelete}
            {...props}
        />
    )
}

