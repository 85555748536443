import { Avatar, AvatarProps, Typography } from "@mui/material";
import { blueGrey, deepPurple, green, indigo, pink, red, teal } from "@mui/material/colors";

import { Account } from "api";

import { AccountLink } from "./AccountLink";

export interface AccountAvatarProps {
    account: Account

    variant?: AvatarProps["variant"]
    size?: "small" | "medium" | "large" | number
}

export const AccountAvatar = ({ account, variant = "rounded", size = "small" }: AccountAvatarProps) => {
    if (size === "small") {
        size = 24
    }
    if (size === "medium") {
        size = 48
    }

    if (size === "large") {
        size = 96
    }

    const color = accountAvatarColor(account)

    return (
        <AccountLink account={account}>
            <Avatar src={account.avatar} variant={variant}
                    sx={{
                        width: size,
                        height: size,
                        bgcolor: color,
                        color: theme => theme.palette.getContrastText(color)
                    }}>
                <Typography fontSize={0.6 * size}>
                    {shortenUsername(account.username)}
                </Typography>
            </Avatar>
        </AccountLink>
    );
}

function shortenUsername(username: string) {
    const terms = username.split(" ")
    return terms.slice(0, 2)
        .map(term => term[0].toUpperCase());
}

const AvatarColors = [
    blueGrey[300],
    indigo[200],
    pink[200],
    teal[300],
    red[300],
    green[300],
    deepPurple[200],
]

function accountAvatarColor(account: Account): string {
    const h = hash(account.id)
    return AvatarColors[(Math.sign(h) * h) % AvatarColors.length]
}

function hash(str: string) {
    var hash = 0,
      i, chr;
    if (str.length === 0) return hash;
    for (i = 0; i < str.length; i++) {
      chr = str.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }