import { Breakpoint as MuiBreakpoint, Theme, useMediaQuery } from "@mui/material";

export type BreakpointOptions<Breakpoint extends string, T> =
    Partial<Record<Breakpoint, T>>
export type BreakpointFn<Breakpoint extends string> =
    (<T>(options: BreakpointOptions<Breakpoint, T>, defaultValue: T) => T)
    & (<T>(options: BreakpointOptions<Breakpoint, T>) => (T | undefined))
    & (() => Breakpoint)

export interface UseBreakpoints<Breakpoint extends string> {
    breakpoint: BreakpointFn<Breakpoint>
}

export interface MapBreakpointOptionsFn<Breakpoint extends string> {
    (): BreakpointOptions<MuiBreakpoint, MuiBreakpoint>;
    <T>(options: BreakpointOptions<Breakpoint, T>): BreakpointOptions<MuiBreakpoint, T>;
}

export function useBreakpoints(): UseBreakpoints<MuiBreakpoint>;
export function useBreakpoints<Breakpoint extends string>(mapper: MapBreakpointOptionsFn<Breakpoint>): UseBreakpoints<Breakpoint>;
export function useBreakpoints<Breakpoint extends string = MuiBreakpoint>(mapper?: MapBreakpointOptionsFn<Breakpoint>): UseBreakpoints<Breakpoint> {
    // TODO(souperk): This can be more general
    const xs = useMediaQuery<Theme>(theme => theme.breakpoints.up("xs"))
    const sm = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"))
    const md = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"))
    const lg = useMediaQuery<Theme>(theme => theme.breakpoints.up("lg"))
    const xl = useMediaQuery<Theme>(theme => theme.breakpoints.up("xl"))

    return {
        breakpoint: (<T = Breakpoint>(options?: BreakpointOptions<MuiBreakpoint, T> | BreakpointOptions<Breakpoint, T>, defaultValue?: T) => {
            if (mapper) {
                options = mapper(options as BreakpointOptions<Breakpoint, T>)
            }
            if (!options) {
                options = {
                    xs: "xs",
                    sm: "sm",
                    md: "md",
                    lg: "lg",
                    xl: "xl",
                } as BreakpointOptions<Breakpoint, T>
            }
            if (xl && "xl" in options) {
                return options.xl
            }
            if (lg && "lg" in options) {
                return options.lg
            }
            if (md && "md" in options) {
                return options.md
            }
            if (sm && "sm" in options) {
                return options.sm
            }
            if (xs && "xs" in options) {
                return options.xs
            }

            return defaultValue
        }) as BreakpointFn<Breakpoint>
    }
}

// export function useBreakpointValue<T>(options: BreakpointOptions<MuiBreakpoint, T> = {}) {
//     const { breakpoint } = useBreakpoints()
//     return breakpoint(options)
// }
