import { ReactNode } from "react";

import { Stack, Toolbar as MuiToolbar } from "@mui/material";

export interface ToolbarProps {
    children?: ReactNode
}

export const Toolbar = ({ children }: ToolbarProps) => {
    return (
        <MuiToolbar>
            <Stack display="inline-flex" direction="row" gap={2}
                   alignItems="center" sx={{ width: "100%" }}>
                {children}
            </Stack>
        </MuiToolbar>
    )
}

