import { Divider, Grid, Stack, Typography } from "@mui/material";
import { Trip } from "../../api";
import { useBreakpoints } from "../../commons/hooks";
import { TripTimeline } from "./TripTimeline";

export interface TripCardContentProps {
    trip: Trip
}

export const TripCardContent = ({ trip }: TripCardContentProps) => {
    const { breakpoint } = useBreakpoints()

    return (
        <Grid container>
            <Grid item xs={12} lg={4}>
                <TripTimeline trip={trip}/>
            </Grid>
            <Grid item xs={12} lg={8}>
                <Stack
                    direction={breakpoint({ xs: "column", lg: "row" })}
                    gap={2}
                    sx={{
                        height: { lg: "100%" },
                        width: "100%"
                    }}
                >
                    <Divider
                        orientation={breakpoint({ xs: "horizontal", lg: "vertical" })}
                        sx={{ width: { lg: "0%" } }}/>
                    <Typography variant="body1">
                        {trip.summary}
                    </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}