import { Box, Grid } from "@mui/material";

export const AccountNotFoundPage = () => {
    return (
        <Grid container>
            <Box>
                Account not found
            </Box>
        </Grid>
    )
}