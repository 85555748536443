import { Stack } from "@mui/material";

import { RatingLike, RatingToggle, RatingUtils } from "features/rating";

import { ReviewFormGuideline } from "./ReviewFormGuideline";

export interface ReviewFormRatingProps {
    rating: RatingLike
    onPositive: () => void
    onNegative: () => void
}

export const ReviewFormRating = ({ rating, onPositive, onNegative }: ReviewFormRatingProps) => {
    return (
        <Stack gap={2}>
            <Stack gap={4} direction="row" justifyContent="center" flexWrap="wrap">
                <RatingToggle
                    rating="negative"
                    value={RatingUtils.isNegative(rating)}
                    onChange={onNegative}
                    sx={{ fontSize: 48, padding: 2 }}
                />
                <RatingToggle
                    rating="positive"
                    value={RatingUtils.isPositive(rating)}
                    onChange={onPositive}
                    sx={{ fontSize: 48, padding: 2 }}
                />
            </Stack>
            <ReviewFormGuideline rating={rating}/>
        </Stack>
    )
}