import { Chip, Grid, Stack } from "@mui/material";

import { Trip } from "api";
import { Timestamp } from "components";
import { PeopleIcon } from "components/icons";
import { AccountAvatar, AccountLink, AccountRatingChip } from "features/account";

import { TripCardActions } from "./TripCardActions";

export interface TripCardHeaderProps {
    trip: Trip
}

export const TripCardHeader = ({ trip }: TripCardHeaderProps) => {
    return (
        <Grid
            container
            display="grid"
            alignItems="center"
            columnGap={1}
            gridTemplateAreas={`
                "avatar .           actions"
                "avatar username    actions"
                "avatar tags        actions"
                "avatar .           actions"
            `}
            gridTemplateColumns="auto 1fr auto"
        >
            <Grid item gridArea="avatar">
                <AccountAvatar account={trip.creator} variant="rounded" size={48}/>
            </Grid>

            <Grid item gridArea="username">
                <AccountLink account={trip.creator} variant="h6" sx={{ lineHeight: 1 }}/>
            </Grid>

            <Grid item gridArea="tags">
                <Stack direction="row" gap={1}>
                    <Timestamp value={trip.depart_at}/>
                    {/*<Chip label={displayDepartAt(trip.depart_at)} size="small"/>*/}
                    <Chip icon={<PeopleIcon/>} label="1" size="small"/>
                    <AccountRatingChip account={trip.creator} size="small"/>
                </Stack>
            </Grid>

            <Grid item gridArea="actions">
                <TripCardActions trip={trip}/>
            </Grid>
        </Grid>
    )
}

