import { useEffect, useMemo, useRef } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { AppDispatch, RootState } from "store";


export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 * @param {Array<any>} deps
 */
export function useUpdateEffect(effect: () => void, deps: unknown[] = []) {

    const didMountRef = useRef(false);

    useEffect(() => {
        if (didMountRef.current) {
            return effect();
        }
        didMountRef.current = true;
        // Suppress exhaustive deps because it's safe to ignore changes to
        //  didMountRef since it's controlled by this effect.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps, effect]);
}
