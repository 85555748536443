import { Stack, Typography } from "@mui/material";

import { useSearchTripsQuery, Location } from "api";
import { useAppSelector } from "hooks";

import { TripCard } from "features/trip";

import { tripFilterSelector } from "./trip-filter-reducer";

export const TripSearchListing = () => {
    const { from, to } = useAppSelector(tripFilterSelector)
    const { data: searchResult } = useSearchTripsQuery({
        from: from as Location,
        to: to as Location
    }, { skip: !from || !to })

    // TODO(souperk): Use markdown rendering for summary.
    return (
        <Stack gap={1}>
            <Typography variant="body1" component="div">
                Found {searchResult?.total || 0} matches!
            </Typography>
            <Stack gap={1}>
                {(searchResult?.items || []).map(trip => (
                    <TripCard key={trip.id} trip={trip}/>
                ))}
            </Stack>
        </Stack>

    )
}

