import { ReactNode } from "react";

import { Grid } from "@mui/material";

export interface PageProps {
    children: ReactNode
}

export const Page = ({ children }: PageProps) => {
    return (
        <Grid container direction="column"
              sx={{
                  width: "100vw",
                  height: "100vh",
                  overflowY: "auto"
              }}>
            {children}
        </Grid>
    )
}

export interface PageContentProps {
    children: ReactNode
}

export const PageContent = ({ children }: PageContentProps) => {
    return (
        <Grid item flex="1"
              sx={{
                  overflowY: "auto",
                  padding: theme => ({
                      "xs": theme.spacing(1),
                      "lg": theme.spacing(1, 2),
                  }),
              }}>
            {children}
        </Grid>
    )
}


