import { ReactElement } from "react";

import { alpha } from "@mui/system";
import { SystemStyleObject } from "@mui/system/styleFunctionSx/styleFunctionSx";
import { Box, Stack, SvgIconProps, SxProps, Theme, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";

import { RatingIcon } from "./RatingIcon";
import { RatingLike, RatingUtils } from "./utils";

export interface RatingProps {
    rating: RatingLike

    active?: boolean
    onClick?: () => void

    variant?: "outline" | "flat"
    label?: string
    icon?: ReactElement<SvgIconProps>

    sx?: SxProps<Theme> & {
        active?: SystemStyleObject<Theme> & {
            positive?: SystemStyleObject<Theme>
            negative?: SystemStyleObject<Theme>
        }
        hover?: SystemStyleObject<Theme> & {
            positive?: SystemStyleObject<Theme>
            negative?: SystemStyleObject<Theme>
        }
    }
}

export const Rating = ({
    rating,
    active = false,
    onClick,
    variant = "outline",
    label,
    icon,
    sx = {}
}: RatingProps) => {
    const theme = useTheme()

    const {
        active: { positive: sxActivePositive, negative: sxActiveNegative, ...sxActive } = {},
        hover: { positive: sxHoverPositive, negative: sxHoverNegative, ...sxHover } = {},
        ...sxRest
    } = sx

    const buildStyle = (color: string) => {
        if (variant === "outline") {
            return {
                color: color,
                borderColor: color,
                backgroundColor: alpha(color, 0.08),
            }
        }

        return { color }
    }

    const styles = {
        variants: {
            outline: {
                border: 2,
                borderRadius: 2,
                padding: theme.spacing(1, 2),
            },
            flat: {},
        },
        active: {
            ...buildStyle(grey[600]),

            positive: {
                ...buildStyle(theme.palette.success.main),
            },

            negative: {
                ...buildStyle(theme.palette.error.main),
            }
        }
    }

    return (
        <Box
            onClick={onClick}
            sx={{
                ...(styles.variants[variant]),

                ...sxRest,

                ...(active ? {
                    ...styles.active,
                    ...sxActive,
                } : undefined),
                ...(active && RatingUtils.isPositive(rating) ? {
                    ...styles.active.positive,
                    ...sxActivePositive,
                } : undefined),
                ...(active && RatingUtils.isNegative(rating) ? {
                    ...styles.active.negative,
                    ...sxActiveNegative,
                } : undefined),

                ":hover": {
                    ...(sx.hover ? sxHover : sxActive),
                    ...(RatingUtils.isPositive(rating) ? (sxHoverPositive || sxActivePositive) : undefined),
                    ...(RatingUtils.isNegative(rating) ? (sxHoverNegative || sxActiveNegative) : undefined),
                }
            }}>
            <Stack alignItems="center" gap={1}>
                {icon || <RatingIcon rating={rating} fontSize="inherit"/>}
                {label}
            </Stack>
        </Box>
    )
}

