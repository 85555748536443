import { Grid, GridProps } from "@mui/material";
import React from "react";


export interface SpacerProps extends GridProps {
}

export const Spacer = ({ flex = 1, ...props }: SpacerProps) => {
    return <Grid item flex={flex} {...props}/>
}
