import {
    cloneElement,
    forwardRef,
    ReactElement, ReactNode,
    useImperativeHandle,
    useState
} from "react";

import {
    Box,
    Modal as MuiModal,
    ModalProps as MuiModalProps
} from "@mui/material"


export interface ModalTriggerProps {
    onClick?: () => void
}

export interface ModalRef {
    isOpen: boolean

    open(): void

    close(): void
}

export interface ModalProps extends Omit<MuiModalProps, "ref" | "open" | "children"> {
    open?: MuiModalProps["open"]
    onOpen?: () => void
    onClose?: () => void

    trigger?: ReactElement<ModalTriggerProps>
    onTriggerClick?: () => void

    children?: ReactNode
}

export const Modal = forwardRef<ModalRef | undefined, ModalProps>(({
    onOpen,
    onClose,
    trigger,
    onTriggerClick,
    children,
    ...props
}, ref) => {
    const [open, setOpen] = useState(false)

    const handleDialogClose = () => {
        setOpen(false)
        onClose?.()
    }
    const handleTriggerClick = () => {
        onTriggerClick?.()
        setOpen(true)
        onOpen?.()
    }

    useImperativeHandle(ref, () => {
        return {
            isOpen: open,
            open: () => {
                console.log("modal open")
                setOpen(true)
                onOpen?.()
            },
            close: () => {
                console.log("modal close")
                setOpen(false)
                onClose?.()
            },
        }
    })

    return (
        <>
            {trigger && cloneElement(trigger, { onClick: handleTriggerClick })}
            <MuiModal
                open={open}
                onClose={handleDialogClose}
                {...props}
            >
                <Box sx={{ display: "flex", width: "100vw", height: "100vh" }}>
                    {children}
                </Box>
            </MuiModal>
        </>
    )

})
