import { SvgIconProps } from "@mui/material";

import { ReviewPositiveIcon, ReviewNegativeIcon } from "components/icons";

import { RatingLike, RatingUtils } from "./utils";

export interface RatingIconProps extends SvgIconProps {
    rating: RatingLike
}

export const RatingIcon = ({ rating, ...props }: RatingIconProps) => {
    if (RatingUtils.isPositive(rating)) {
        return <ReviewPositiveIcon {...props}/>
    }
    if (RatingUtils.isNegative(rating)) {
        return <ReviewNegativeIcon {...props}/>
    }
    // return <NotSureIcon {...props}/>
    return null
}

