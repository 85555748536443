import { Grid } from "@mui/material";

import { useAppSelector } from "hooks";

import { Location } from "api"
import { PageHeader, Page, PageContent } from "components";
import { useAppBreakpoints } from "commons/hooks";

import { tripFilterSelector } from "./trip-filter-reducer";
import { TripSearchListing } from "./TripSearchListing";
import TripSearch from "./TripSearch";
import { TripMap } from "./TripMap";


export const TripSearchPage = () => {
    const {
        from,
        to
    } = useAppSelector(tripFilterSelector)

    const { breakpoint } = useAppBreakpoints()
    const mode = breakpoint()

    return (
        <Page>
            <PageHeader>
                <TripSearch/>
            </PageHeader>
            <PageContent>
                <Grid container gap={4} justifyContent="stretch"
                      sx={{ height: "100%" }}>
                    {mode === "desktop" && (
                        <Grid item flex="3"
                              sx={{
                                  height: "100%"
                              }}>
                            <TripMap stops={[from, to].filter(x => x !== null) as Location[]}/>
                        </Grid>
                    )}

                    <Grid item flex={{ xs: "0 0 100%", lg: "9" }}
                          sx={{
                              maxHeight: { lg: "100%" },
                              overflowY: { lg: "auto" },
                          }}>
                        <TripSearchListing/>
                    </Grid>
                </Grid>
            </PageContent>
        </Page>
    )
}
