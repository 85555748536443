import { ForwardedRef, forwardRef, ReactNode, useImperativeHandle, useRef } from "react";

import { Button, Paper, Stack, Typography } from "@mui/material";

import { Spacer } from "../Spacer";

import { Dialog, DialogProps } from "./Dialog";
import { ModalRef } from "./Modal";

export interface MessageDialogProps extends Omit<DialogProps, "title"> {
    title?: ReactNode
    message: ReactNode
}

export const MessageDialog = forwardRef(
    function MessageDialog({
        title,
        message,
        ...props
    }: MessageDialogProps, ref: ForwardedRef<ModalRef | undefined>) {
        const dialog = useRef<ModalRef>()
        useImperativeHandle(ref, () => dialog.current)

        if (typeof title === "string") {
            // wrap with Typography
            title = (
                <Typography variant="h6">
                    {title}
                </Typography>
            )
        }

        if (typeof message === "string") {
            // wrap with Typography
            message = (
                <Typography variant="body1">
                    {message}
                </Typography>
            )
        }

        const close = () => {
            dialog.current?.close?.()
        }

        return (
            <Dialog
                ref={dialog}
                width={320} height={200}
                {...props}
            >
                <Stack height="100%">
                    {title && (
                        <Paper square elevation={0} sx={{
                            padding: theme => theme.spacing(1, 2),
                            bgcolor: "grey.200",
                        }}>
                            {title}
                        </Paper>
                    )}

                    <Spacer sx={{ padding: theme => theme.spacing(1, 2) }}>
                        {message}
                    </Spacer>

                    <Paper square elevation={0} sx={{
                        padding: theme => theme.spacing(1, 2),
                        bgcolor: "grey.200",
                    }}>
                        <Stack>
                            <Button onClick={close} sx={{ flex: 1 }}>OK</Button>
                        </Stack>
                    </Paper>

                </Stack>
            </Dialog>
        )
    }
)