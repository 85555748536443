import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";

import { AccountRoute, TripRoute, TripSearchRoute, LoginRoute } from "features";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <Navigate to="home"/>
    },
    {
        path: "home",
        element: <TripSearchRoute/>
    },
    {
        path: "login",
        element: <LoginRoute/>
    },
    {
        path: "trip/:tripId",
        element: <TripRoute/>
    },
    {
        path: "account/:accountId",
        element: <AccountRoute/>
    }
])