import { createContext, ReactNode } from "react";
import Leaflet from "leaflet"

export type _LeafletContextData = {
    map: Leaflet.Map
}

export type LeafletContextData = ({ initialized: true } & _LeafletContextData)
    | ({ initialized: false } & Record<keyof _LeafletContextData, undefined>)

export const LeafletUninitializedContext = { initialized: false } as LeafletContextData


export const LeafletContext = createContext<LeafletContextData>({ initialized: false } as LeafletContextData)

export interface LeafletContextProviderProps {
    value: LeafletContextData
    children: ReactNode
}

export const LeafletContextProvider = ({ value, children }: LeafletContextProviderProps) => {
    return (
        <LeafletContext.Provider value={value}>
            {children}
        </LeafletContext.Provider>
    )
}
